import React, { useEffect, useRef, useState } from 'react'

const HubspotContactForm = (props) => {
    //    <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
    //<script>
    //  hbspt.forms.create({
    //    region: "na1",
    //    portalId: "24234338",
    //    formId: "a33420b4-b499-4313-ad04-49b551d14359"
    //  });
    //</script>



    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/embed/v2.js'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    target: '#hubspotForm',
                    region: "na1",
                    portalId: "24234338",
                    formId: "a33420b4-b499-4313-ad04-49b551d14359"
                    //...props
                })
            }
        })

        //var cssLink = document.createElement("link");
        //cssLink.href = "style.css";
        //cssLink.rel = "stylesheet";
        //cssLink.type = "text/css";
        //window.frames['hubspotForm'].document.head.appendChild(cssLink);
        //window?.document?.querySelector('hubspotForm')?.contentWindow?.document?.querySelector("h1#firstHeading")?.style?.color = "red";

    }, [props])

    return (
        <div
            id="hubspotForm"
        //style={{ overflow: 'hidden', height: '60%' }}
        >
        </div>
    )
}

export default HubspotContactForm