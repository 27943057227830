import axios from "axios";
import React, { FormEvent, useState } from "react";
import HubspotContactForm from "../Custom/HubspotContactForm";

import { Container, Form } from "./styles";

const ReceiveEmail: React.FC = () => {

  //const [email,setEmail]=useState('')
  //const handleSubmit =  async (e: FormEvent) => {
  //  e.preventDefault();
  //  await axios.post('https://firestore.googleapis.com/v1/projects/codeshop-afce9/databases/(default)/documents/mail?&key=AIzaSyCsaV06rDiEVih6fXiws1SyI2hAsRL7UrM',
  //  {fields: {email:{
  //    stringValue:email
  //  }}},{})
  // .then(()=>alert("Sucesso, aguarde instruções no e-mail"))
  //  .catch((err)=>{alert("Erro, tente novamente!")
  //console.log(err)
  //})
  //};

  return (
    <Container>


      <Form
      // onSubmit={handleSubmit}
      >
        <label htmlFor="email">
          Entenda os beneficios 
          <br />
          <strong>e reserve a sua oferta!</strong>
        </label>
        {/*<div className="group">
          <input name="email" value={email} onChange={e=>setEmail(e.target.value)} type="email" placeholder="email@gmail.com" />
        </div>
          <button type="submit" className="btn active">
            Enviar
          </button>*/}
      </Form>
      <HubspotContactForm props={{
        region: "na1",
        portalId: "24234338",
        formId: "a33420b4-b499-4313-ad04-49b551d14359"
      }} />
      <p className="copyright">
        {new Date().getFullYear()} Snepshop
      </p>
    </Container>
  );
};

export default ReceiveEmail;
