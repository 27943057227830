import React from "react";
import "./style.css"

// Components
import Header from "./components/Header";
import Spotlight from "./components/Spotlight";
import WhatIs from "./components/WhatIs";
import Contagion from "./components/Contagion";
import Symptomps from "./components/Symptomps";
import WhatShouldWeDo from "./components/WhatShouldWeDo";
import Footer from "./components/Footer";
import HeaderSS from "./components/HeaderSS";
import ReceiveEmail from "./components/ReceiveEmail";
import HubspotContactForm from "./components/Custom/HubspotContactForm";

function App() {
  return (
    <>
      {/*<Header />*/}
      <HeaderSS />
      {/*<Spotlight />*/}
      {/*<WhatIs />*/}
      {/*<Contagion />*/}
      {/*<Symptomps />*/}
      {/*<WhatShouldWeDo />*/}
      <ReceiveEmail />
      {/*<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
<script>
  hbspt.forms.create({
    region: "na1",
    portalId: "24234338",
    formId: "a33420b4-b499-4313-ad04-49b551d14359"
  });
</script>*/}
      {/*<Footer />*/}
    </>
  );
}

export default App;
