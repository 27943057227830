import React from "react";

import { Container } from "./styles";
const Logo = 'https://http2.mlstatic.com/storage/mshops-appearance-api/images/2/215823502/logo-2023013004552001200.webp'

const HeaderSS: React.FC = () => {
  return (
    <Container>
      <div>
        <a href="/" >
            <img src={Logo} alt="Snepshop"style={{width:'180px'}} />
        </a>
      </div>
    </Container>
  );
};

export default HeaderSS;
